<template>
  <van-form @submit="onSubmit">
    <van-cell-group title=" " inset>
      <van-field
        readonly
        clickable
        name="picker"
        :model-value="isEdit ? row.category.name : category.current.name"
        :disabled="isEdit"
        label="商品分类"
        placeholder="点击选择"
        @click="category.show = true"
      />
      <van-field name="image" label="商品主图">
        <template #input>
          <van-uploader
            v-model="row.image"
            :max-count="1"
            :max-size="5 * 1024 * 1024"
            :after-read="afterRead"
            result-type="file"
          />
        </template>
      </van-field>
      <van-field
        v-model="row.name"
        name="name"
        label="商品名称"
        placeholder="请输入"
      />
      <van-field
        v-model="row.price"
        :disabled="row.skus?.length > 0"
        name="price"
        type="number"
        label="商品价格"
        placeholder="0.1~1000000之间"
      />
      <van-field
        v-model="row.stock"
        :disabled="row.skus?.length > 0"
        type="digit"
        name="stock"
        label="库存数量"
        placeholder="0为无库存"
        v-if="isEdit"
      />
      <van-field
        v-model="row.purchaseLimit"
        type="digit"
        name="purchaseLimit"
        label="限购件数"
        placeholder="0为不限购"
        v-if="isEdit"
      />
      <van-field
        v-model="row.singlePurchaseQuantity"
        type="digit"
        name="singlePurchaseQuantity"
        label="凭证数比"
        placeholder="购买1件，生成多少张使用凭证，默认为1张"
        v-if="isEdit && (row.bizType === 'BOOKING' || row.bizType === 'BOOKING_IOT')"
      />
      <van-field
        v-model="row.sort"
        type="digit"
        name="sort"
        label="权重"
        placeholder="数字越大显示越靠前"
        v-if="isEdit"
      />
      <van-field name="allowRefund" label="预约人信息" v-if="isEdit && row.bizType === 'BOOKING'">
        <template #input>
          <van-switch v-model="row.isRequired" size="20" :active-value="1" :inactive-value="0"/>
        </template>
      </van-field>
      <van-field name="allowRefund" label="允许退款" v-if="isEdit">
        <template #input>
          <van-switch v-model="row.allowRefund" size="20" />
        </template>
      </van-field>
      <van-field name="onSale" label="上架" v-if="isEdit">
        <template #input>
          <van-switch v-model="row.onSale" size="20" />
        </template>
      </van-field>
      <van-field
        v-model="row.description"
        name="description"
        rows="1"
        autosize
        label="一句话描述"
        type="textarea"
        placeholder="请输入"
        v-if="isEdit"
      />
      <van-field name="swipes" label="轮播图" v-if="isEdit">
        <template #input>
          <van-uploader
            v-model="row.swipes"
            :max-count="5"
            :max-size="5 * 1024 * 1024"
            :after-read="afterRead"
          />
        </template>
      </van-field>
      <van-field
        v-model="tag.current.name"
        readonly
        clickable
        name="picker"
        label="自定义分类"
        placeholder="点击选择"
        @click="tag.show = true"
        v-if="isEdit"
      />
      <van-popup v-model:show="tag.show" position="bottom">
        <van-picker
          :columns="tag.items"
          value-key="name"
          @confirm="onConfirmTag"
          @cancel="tag.show = false"
        />
      </van-popup>
    </van-cell-group>
    <van-cell-group title="图文详情（最多10张）" inset v-if="isEdit">
      <van-field name="detailImages" label="详情图">
        <template #input>
          <van-uploader
            v-model="row.detailImages"
            :max-count="10"
            :max-size="5 * 1024 * 1024"
            :after-read="afterRead"
          />
        </template>
      </van-field>
      <van-field
        v-model="row.detailText"
        name="detailText"
        rows="1"
        autosize
        label="详情介绍"
        type="textarea"
        placeholder="请输入"
      />
    </van-cell-group>
    <van-cell-group title="分享海报（建议尺寸1080 * 1920）" inset v-if="isEdit">
      <van-field name="posterImage" label="海报图片">
        <template #input>
          <van-uploader
            v-model="row.posterImage"
            :max-count="1"
            :max-size="5 * 1024 * 1024"
            :after-read="afterRead"
            result-type="file"
          />
        </template>
      </van-field>
      <!-- <van-field name="posterShowInviter" label="扩展信息">
        <template #input>
          <van-switch v-model="row.posterShowInviter" size="20" />
        </template>
      </van-field> -->
    </van-cell-group>
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit">
        提交
      </van-button>
    </div>
    <template v-if="isEdit">
      <div style="margin: 16px;">
        <van-button round block :to="{ name: 'ProductSku', query: { productId: row.productId } }">
          产品规格
        </van-button>
      </div>
      <div style="margin: 16px;" v-if="row.bizType === 'BOOKING'">
        <van-button round block :to="{ name: 'ProductTechnician', query: { productId: row.productId } }">
          绑定技师
        </van-button>
      </div>
      <!-- <div style="margin: 16px;">
        <van-button round block :to="{ name: 'ProductGroupon', query: { productId: row.productId } }">
          团购设置
        </van-button>
      </div> -->
      <div style="margin: 16px;">
        <van-button round block :to="{ name: 'ProfitSharingSettings', query: { productId: row.productId } }">
          分账设置
        </van-button>
      </div>
    </template>
  </van-form>
  <van-popup v-model:show="category.show" position="right" :style="{ width: '80%', height: '100%', background: '#fff' }">
    <category @select="onConfirmCategory"/>
  </van-popup>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast } from 'vant'
import { upload } from '@/util/util'
import Category from '../../../components/Category'

export default {
  components: {
    Category
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const useRoute = inject('useRoute')
    const useRouter = inject('useRouter')
    const state = reactive({
      storeId: Cookies.get('storeId'),
      productId: useRoute.query.productId,
      advanceRefundDays: false,
      advancePurchaseDays: false,
      coverImage: [],
      swipeImage: [],
      detailImage: [],
      posterImage: [],
      category: {
        show: false,
        current: {}
      },
      tag: {
        show: false,
        current: {},
        items: []
      },
      qiniu: {},
      row2: {
        category: {},
        tagIds: 1,
        stock: 1,
        description: 1,
        detailText: 1,
        sort: 1,
        onSale: 1,
        advanceRefundDays: 1,
        advancePurchaseDays: 1,
        purchaseLimit: 1,
        singlePurchaseQuantity: 1,
        firstPurchaseDiscount: 1,
        repurchaseDiscount: 1,
        grouponEnable: false,
        grouponDays: 1,
        grouponParticipants: 1,
        grouponDiscount: 0.9
      },
      row: {
        category: {},
        biz: '',
        isRequired: 0
      }
    })
    const onConfirmCategory = (row) => {
      state.category.current = row
      state.category.show = false
    }
    const onConfirmTag = (row) => {
      state.tag.current = row
      state.tag.show = false
    }
    const onSubmit = (values) => {
      console.log(values)
      if (props.isEdit) {
        Toast.loading({ duration: 0, forbidClick: true })
        values.categoryId = state.category.current.id
        post('/product.update', {
          ...state.row,
          ...values,
          image: values.image.length > 0 ? values.image[0].url : '',
          swipes: values.swipes.length > 0 ? values.swipes.map(v => { return v.url }) : [],
          detailImages: values.detailImages.length > 0 ? values.detailImages.map(v => { return v.url }) : [],
          posterImage: values.posterImage.length > 0 ? values.posterImage[0].url : '',
          productId: state.row.productId,
          tagId: state.tag.current.tagId
        }).then((res) => {
          Toast.clear()
          if (res.code === 0) {
            useRouter.go(-1)
          } else {
            Toast(res.msg)
          }
        })
      } else {
        Toast.loading({ duration: 0, forbidClick: true })
        values.categoryId = state.category.current.id
        post('/product.create', {
          ...values,
          image: values.image.length > 0 ? values.image[0].url : '',
          storeId: state.storeId,
          categoryId: state.category.current.id
        }).then((res) => {
          Toast.clear()
          if (res.code === 0) {
            useRouter.go(-1)
          } else {
            Toast(res.msg)
          }
        })
      }
    }
    const afterRead = (file) => {
      upload({
        fileBlob: file.file,
        token: state.qiniu.token,
        domain: state.qiniu.domain
      }).then(res => {
        file.url = res.img
        // file.status = ''
        // file.message = ''
      })
    }
    const init = () => {
      if (props.isEdit) {
        post('/product.get', {
          productId: state.productId
        }).then(res => {
          state.row = Object.assign({}, res.data)
          state.tag.current = state.row.tag ?? {}
          state.row.image = []
          state.row.image.push({
            url: res.data.image,
            isImage: true
          })
          state.row.swipes = []
          for (const item of res.data.swipes) {
            state.row.swipes.push({
              url: item,
              isImage: true
            })
          }
          state.row.detailImages = []
          for (const item of res.data.detailImages) {
            state.row.detailImages.push({
              url: item,
              isImage: true
            })
          }
          state.row.posterImage = []
          if (res.data.posterImage !== '') {
            state.row.posterImage.push({
              url: res.data.posterImage,
              isImage: true
            })
          }
        })
        post('/tag.list', {
          storeId: state.storeId
        }).then(res => {
          state.tag.items.push({
            tagId: undefined,
            name: '无需分类'
          })
          for (const v of res.data.content) {
            state.tag.items.push({
              tagId: v.tagId,
              name: v.name
            })
          }
        })
      }
      post('/util.getQiniuToken', {
      }).then(res => {
        state.qiniu = res.data
      })
      console.log(Cookies)
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onSubmit,
      afterRead,
      onConfirmCategory,
      onConfirmTag
    }
  }
}
</script>
<style scoped>
</style>
