<template>
  <van-collapse v-model="activeNames" accordion>
    <template v-for="item in list" :key="item">
      <van-collapse-item :title="item.name" :name="item.id">
        <van-cell-group :border="false">
          <template v-for="item2 in item.children" :key="item2">
            <van-cell :title="item2.name" is-link @click="onClick(item2)" />
          </template>
        </van-cell-group>
      </van-collapse-item>
    </template>
  </van-collapse>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'

export default {
  emits: ['select'],
  setup (props, { emit }) {
    const post = inject('post')
    const state = reactive({
      list: [],
      activeNames: undefined
    })
    /*
    const reset = () => {
      state.list = []
      state.page = 1
      state.finished = false
      state.loading = false
    }
    */
    const onClick = (row) => {
      emit('select', row)
    }
    const init = () => {
      post('/category.tree', {
        enable: true
      }).then(res => {
        state.list = res.data.content
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onClick
    }
  }
}
</script>

<style scoped>
</style>
